import React, { useState } from "react"
import { Link, graphql, navigate } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Image from "../components/image"
import Helmet from "react-helmet"
import { FaChevronDown } from "react-icons/fa"
import { ShopNameTag, ShopName, ShopNameSelected, ImageFilename} from '../utils/common'
import moment from 'moment'
import { FaCheckSquare } from "react-icons/fa";
import { FaRegSquare } from "react-icons/fa";
import { IoMdRadioButtonOn } from "react-icons/io";
import { IoMdRadioButtonOff } from "react-icons/io";

const TableDiaperSize = ({ data, location, pageContext: {  _shop_id, shop_id, size_id, option_id } }) => {
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState(shop_id);

	const toggleDropdown = () => { setIsActive(!isActive);};

	const handleItemClick = (_shop_id, shop_id, option_id) => {
    setSelected(shop_id);
    setIsActive(false);
    const url = ShopLink(size_id, _shop_id, option_id)
    navigate(url);
  }

	let targets = [
		// {size:'sss',	type:'tape'},
		// {size:'ss',		type:'tape'},
		// {size:'s',		type:'tape'},
		// {size:'s',		type:'pants'},
		// {size:'m',		type:'tape'},
		// {size:'sm',		type:'pants'},
		// {size:'m',		type:'pants'},
		// {size:'l',		type:'tape'},
		// {size:'l',		type:'pants'},
	]

	let targets2 = [
		// {size:'b',		type:'tape'},
		// {size:'b',		type:'pants'},
		// {size:'bb',		type:'pants'},
		// {size:'bbb',	type:'tape'},
		// {size:'bbb',	type:'pants'},
		// {size:'mizu',	type:'wipe'},
		// {size:'mizu',	type:'handwipe'},
		// {size:'none',	type:'wipe'},
		// {size:'thick',	type:'wipe'},
		// {size:'toire',	type:'wipe'},
	]

	let mainSizeName = nameMainSize(data, size_id) + 'サイズ'
	const title = `${ShopNameTag(shop_id)}${mainSizeName}単価表`
	let description = `${ShopNameTag(shop_id)}${mainSizeName}1枚あたりの価格を比較してパンツ・テープ別に1位から3位までランキング！`

	// 更新日付をGraphQLから直接表示するとUTCとなるため、自前のmoment.jsで表示
	const update = moment().format('YYYY年M月D日')

	// 条件に基づいてitemListをフィルタリングする
	const filteredContentfulItems = data.allContentfulItems.edges.map(edge => {
		// option_idが空文字列でない場合はフィルタリング、空文字列の場合はそのまま
		// const filteredItemList = option_id !== "" 
		// 	? edge.node.itemList.filter(item => !item.txt.includes('らくベビ300') && !item.txt.includes('らくベビ600'))
		// 	: edge.node.itemList;
		let filteredItemList = edge.node.itemList;
		if(option_id === '2'){filteredItemList = edge.node.itemList.filter(item => !item.txt.includes('らくベビ300') && !item.txt.includes('らくベビ600'))} 
		if(option_id === '3'){filteredItemList = edge.node.itemList.filter(item => !item.txt.includes('GOONPLUS2441'))} 
		if(option_id === '4'){filteredItemList = edge.node.itemList.filter(item => !item.txt.includes('らくベビ300') && !item.txt.includes('らくベビ600') && !item.txt.includes('GOONPLUS2441'))} 

		if(shop_id !== 'all'){filteredItemList = filteredItemList.filter(item => item.shopId === shop_id)} 
		
		return {
			...edge,
			node: {
				...edge.node,
				itemList: filteredItemList
			}
		};
	});

  // allContentfulItemsを1枚当たりの価格が安い順に並び替える(Graphqlのソートだと100円以上の場合に並び替えが正しくできないため数値のソートを自前で行う)
	filteredContentfulItems.sort(function(a,b){
		if((!a.node.itemList.length) && (!b.node.itemList.length)){
			return 0
		}
		// itemList無ければ後ろへ並べる
		else if((!a.node.itemList.length) && (b.node.itemList.length)){
			return 1
		}
		else if((a.node.itemList.length) && (!b.node.itemList.length)){
			return -1
		}
		return a.node.itemList[0].per - b.node.itemList[0].per
	})

	// 全ターゲット
	let _targets = [
		{brand:'pampers',	type:'tape'},
		{brand:'goon',	type:'tape'},
		{brand:'merries',	type:'tape'},
		{brand:'mamypoko',	type:'tape'},
		{brand:'moony',	type:'tape'},
		{brand:'genki',	type:'tape'},
		{brand:'mamabear',	type:'tape'},
		// {brand:'oya',	type:'tape'},
		// {brand:'tore',	type:'tape'},
		// {brand:'whito',	type:'tape'},
		// {brand:'topvalue',	type:'tape'},
		// {brand:'mirafeel',	type:'tape'},

		{brand:'pampers',	type:'pants'},
		{brand:'goon',	type:'pants'},
		{brand:'merries',	type:'pants'},
		{brand:'mamypoko',	type:'pants'},
		{brand:'moony',	type:'pants'},
		{brand:'genki',	type:'pants'},
		{brand:'mamabear',	type:'pants'},
		{brand:'keiseidou',	type:'pants'},
		// {brand:'oya',	type:'pants'},
		// {brand:'tore',	type:'pants'},
		// {brand:'whito',	type:'pants'},
		// {brand:'topvalue',	type:'pants'},
		// {brand:'mirafeel',	type:'pants'},
	]

	// 対象ブランドによるターゲット有無をチェック
	let targetCnt_tape = 0;
	let targetCnt_pants = 0;
	_targets.map(_target => {
		const type = data.allTypesYaml.edges.find(edge => {	return ( edge.node.cid === _target.type )})
		const brand = data.allBrandsYaml.edges.find(edge => {	return ( edge.node.cid === _target.brand )})
		const rankItems = gerRankItem(filteredContentfulItems, data, type, brand, size_id)
		if(rankItems[0]){
			_target.num = rankItems.length
			_target.per = rankItems[0].node.itemList[0].per
			if(_target.type === 'tape'){
				targetCnt_tape += 1
			}
			else if(_target.type === 'pants'){
				targetCnt_pants += 1				
			}
		}
		else{
			_target.num = 0
		}
	})

	if(targetCnt_tape > 0){
		// 1列目にターゲットをセット(テープ)
		targets = _targets.filter(_target => 
			0 < _target.num && _target.type === 'tape'
		)
		// 2列目にターゲットをセット(パンツ)
		targets2 = _targets.filter(_target => 
			0 < _target.num && _target.type === 'pants'
		)
	}
	else{
		// 1列目にターゲットをセット(パンツ)
		targets = _targets.filter(_target => 
			0 < _target.num && _target.type === 'pants'
		)
	}

	targets.sort(function(a,b){
		return a.per - b.per
	})	

	targets2.sort(function(a,b){
		return a.per - b.per
	})	

	let targets_1 = false;
	let targets_2 = false;
	let targets_3 = false;
	targets.map(target => {
		if(target.num === 1){
			targets_1 = true;
		}
		else if(target.num === 2){
			targets_1 = true;
			targets_2 = true;
		}
		else if(target.num === 3){
			targets_1 = true;
			targets_2 = true;
			targets_3 = true;
		}
	})

	let targets2_1 = false;
	let targets2_2 = false;
	let targets2_3 = false;
	targets2.map(target => {
		if(target.num === 1){
			targets2_1 = true;
		}
		else if(target.num === 2){
			targets2_1 = true;
			targets2_2 = true;
		}
		else if(target.num === 3){
			targets2_1 = true;
			targets2_2 = true;
			targets2_3 = true;
		}
	})


	// パンくずリストの構造化データ
	const jsonLd = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": [{
			"@type": "ListItem",
			"position": 1,
			"name": "トップ",
			"item": "https://hula-la.com/"
		},{
			"@type": "ListItem",
			"position": 2,
			"name": "オムツ単価表",
			"item": "https://hula-la.com/diaper"+option_id+".html"
		}]
	};

	return (
  <Layout>
		<nav className="breadcrumb is-small" aria-label="breadcrumbs" style={{marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>
			<ul>
				<li><Link to="/">トップ</Link></li>
				<li><Link to={`/diaper${option_id}.html`}>オムツ単価表</Link></li>
			</ul>
		</nav>
			<>
				<Helmet>
     			<script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
   			</Helmet>
				{ option_id !== '' && (
	      	<Helmet><meta name="robots" content="noindex" /></Helmet>
	      )}
				<div className="content">
					<div class="container"style={{marginRight: `0.0rem`}}>
						<div class="columns is-mobile">
							{ size_id === 'sss' && (
								<div class="column" style={{paddingLeft: `0.9rem`, paddingBottom: `0.7rem`}}>
									<Link to={`/sss${_shop_id}${option_id}.html`}><span className="icon"><IoMdRadioButtonOn style={{transform: 'scale(0.8)', color: `#EB9DA8`}} /></span><span style={{fontSize:`0.5rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}><strong>新小</strong></span></Link>
								</div>
							)}
							{ size_id !== 'sss' && (
								<div class="column" style={{paddingLeft: `0.9rem`, paddingBottom: `0.7rem`}}>
									<Link to={`/sss${_shop_id}${option_id}.html`}><span className="icon"><IoMdRadioButtonOff style={{transform: 'scale(0.8)', color: `#EB9DA8`}} /></span><span style={{fontSize:`0.5rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}>新小</span></Link>
								</div>
							)}
							{ size_id === 'ss' && (
								<div class="column" style={{paddingLeft: `0.0rem`, paddingBottom: `0.7rem`}}>
									<Link to={`/ss${_shop_id}${option_id}.html`}><span className="icon"><IoMdRadioButtonOn style={{transform: 'scale(0.8)', color: `#EB9DA8`}} /></span><span style={{fontSize:`0.5rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}><strong>新</strong></span></Link>
								</div>
							)}
							{ size_id !== 'ss' && (
								<div class="column" style={{paddingLeft: `0.0rem`, paddingBottom: `0.7rem`}}>
									<Link to={`/ss${_shop_id}${option_id}.html`}><span className="icon"><IoMdRadioButtonOff style={{transform: 'scale(0.8)', color: `#EB9DA8`}} /></span><span style={{fontSize:`0.5rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}>新</span></Link>
								</div>
							)}
							{ size_id === 's' && (
								<div class="column" style={{paddingLeft: `0.0rem`, paddingBottom: `0.7rem`}}>
									<Link to={`/s${_shop_id}${option_id}.html`}><span className="icon"><IoMdRadioButtonOn style={{transform: 'scale(0.8)', color: `#EB9DA8`}} /></span><span style={{fontSize:`0.5rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}><strong>S</strong></span></Link>
								</div>
							)}
							{ size_id !== 's' && (
								<div class="column" style={{paddingLeft: `0.0rem`, paddingBottom: `0.7rem`}}>
									<Link to={`/s${_shop_id}${option_id}.html`}><span className="icon"><IoMdRadioButtonOff style={{transform: 'scale(0.8)', color: `#EB9DA8`}} /></span><span style={{fontSize:`0.5rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}>S</span></Link>
								</div>
							)}
							{ size_id === 'sm' && (
								<div class="column" style={{paddingLeft: `0.0rem`, paddingBottom: `0.7rem`}}>
									<Link to={`/sm${_shop_id}${option_id}.html`}><span className="icon"><IoMdRadioButtonOn style={{transform: 'scale(0.8)', color: `#EB9DA8`}} /></span><span style={{fontSize:`0.5rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}><strong>M小</strong></span></Link>
								</div>
							)}
							{ size_id !== 'sm' && (
								<div class="column" style={{paddingLeft: `0.0rem`, paddingBottom: `0.7rem`}}>
									<Link to={`/sm${_shop_id}${option_id}.html`}><span className="icon"><IoMdRadioButtonOff style={{transform: 'scale(0.8)', color: `#EB9DA8`}} /></span><span style={{fontSize:`0.5rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}>M小</span></Link>
								</div>
							)}
							{ size_id === 'm' && (
								<div class="column" style={{paddingLeft: `0.0rem`, paddingBottom: `0.7rem`}}>
									<Link to={`/m${_shop_id}${option_id}.html`}><span className="icon"><IoMdRadioButtonOn style={{transform: 'scale(0.8)', color: `#EB9DA8`}} /></span><span style={{fontSize:`0.5rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}><strong>M</strong></span></Link>
								</div>
							)}
							{ size_id !== 'm' && (
								<div class="column" style={{paddingLeft: `0.0rem`, paddingBottom: `0.7rem`}}>
									<Link to={`/m${_shop_id}${option_id}.html`}><span className="icon"><IoMdRadioButtonOff style={{transform: 'scale(0.8)', color: `#EB9DA8`}} /></span><span style={{fontSize:`0.5rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}>M</span></Link>
								</div>
							)}
							{ size_id === 'l' && (
								<div class="column" style={{paddingLeft: `0.0rem`, paddingBottom: `0.7rem`}}>
									<Link to={`/l${_shop_id}${option_id}.html`}><span className="icon"><IoMdRadioButtonOn style={{transform: 'scale(0.8)', color: `#EB9DA8`}} /></span><span style={{fontSize:`0.5rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}><strong>L</strong></span></Link>
								</div>
							)}
							{ size_id !== 'l' && (
								<div class="column" style={{paddingLeft: `0.0rem`, paddingBottom: `0.7rem`}}>
									<Link to={`/l${_shop_id}${option_id}.html`}><span className="icon"><IoMdRadioButtonOff style={{transform: 'scale(0.8)', color: `#EB9DA8`}} /></span><span style={{fontSize:`0.5rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}>L</span></Link>
								</div>
							)}
							{ size_id === 'b' && (
								<div class="column" style={{paddingLeft: `0.0rem`, paddingBottom: `0.7rem`}}>
									<Link to={`/b${_shop_id}${option_id}.html`}><span className="icon"><IoMdRadioButtonOn style={{transform: 'scale(0.8)', color: `#EB9DA8`}} /></span><span style={{fontSize:`0.5rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}><strong>B</strong></span></Link>
								</div>
							)}
							{ size_id !== 'b' && (
								<div class="column" style={{paddingLeft: `0.0rem`, paddingBottom: `0.7rem`}}>
									<Link to={`/b${_shop_id}${option_id}.html`}><span className="icon"><IoMdRadioButtonOff style={{transform: 'scale(0.8)', color: `#EB9DA8`}} /></span><span style={{fontSize:`0.5rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}>B</span></Link>
								</div>
							)}
							{ size_id === 'bb' && (
								<div class="column" style={{paddingLeft: `0.0rem`, paddingBottom: `0.7rem`}}>
									<Link to={`/bb${_shop_id}${option_id}.html`}><span className="icon"><IoMdRadioButtonOn style={{transform: 'scale(0.8)', color: `#EB9DA8`}} /></span><span style={{fontSize:`0.5rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}><strong>B大</strong></span></Link>
								</div>
							)}
							{ size_id !== 'bb' && (
								<div class="column" style={{paddingLeft: `0.0rem`, paddingBottom: `0.7rem`}}>
									<Link to={`/bb${_shop_id}${option_id}.html`}><span className="icon"><IoMdRadioButtonOff style={{transform: 'scale(0.8)', color: `#EB9DA8`}} /></span><span style={{fontSize:`0.5rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}>B大</span></Link>
								</div>
							)}
							{ size_id === 'bbb' && (
								<div class="column" style={{paddingLeft: `0.0rem`, paddingBottom: `0.7rem`}}>
									<Link to={`/bbb${_shop_id}${option_id}.html`}><span className="icon"><IoMdRadioButtonOn style={{transform: 'scale(0.8)', color: `#EB9DA8`}} /></span><span style={{fontSize:`0.5rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}><strong>SB</strong></span></Link>
								</div>
							)}
							{ size_id !== 'bbb' && (
								<div class="column" style={{paddingLeft: `0.0rem`, paddingBottom: `0.7rem`}}>
									<Link to={`/bbb${_shop_id}${option_id}.html`}><span className="icon"><IoMdRadioButtonOff style={{transform: 'scale(0.8)', color: `#EB9DA8`}} /></span><span style={{fontSize:`0.5rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}>SB</span></Link>
								</div>
							)}
						</div>
					</div>
					<h3 className="title is-6" style={{marginTop: `0.3rem`, marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>{ShopNameTag(shop_id)}{mainSizeName}単価表<small> ({update}更新)</small></h3>
					<div class="container" style={{marginRight: `0.0rem`}}>
						<div class="columns is-mobile">
							<div class="column is-5" style={{paddingTop: `0.8rem`, display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
							{ (shop_id === 'all' || shop_id === 'amazon') && (
              	<>
								{ option_id === '' && (
									<p>
									{/* <Link to={`/${size_id}${_shop_id}2.html`}><span className="icon"><FaRegSquare style={{color: `#EB9DA8`}} /></span><span style={{fontSize:`0.7rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}>らくベビ使用済</span></Link><br /> */}
									{ (size_id === 'ss' || size_id === 's' || size_id === 'm') && (
										<Link to={`/${size_id}${_shop_id}3.html`}><span className="icon"><FaRegSquare style={{color: `#EB9DA8`}} /></span><span style={{fontSize:`0.7rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}>クーポンコード使用済</span></Link>
									)}
									</p>
								)}
								{ option_id === '2' && (
									<p>
									{/* <Link to={`/${size_id}${_shop_id}.html`}><span className="icon"><FaCheckSquare style={{color: `#EB9DA8`}} /></span><span style={{fontSize:`0.7rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}><strong>らくベビ使用済</strong></span></Link><br /> */}
									{ (size_id === 'ss' || size_id === 's' || size_id === 'm') && (
										<Link to={`/${size_id}${_shop_id}4.html`}><span className="icon"><FaRegSquare style={{color: `#EB9DA8`}} /></span><span style={{fontSize:`0.7rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}>クーポンコード使用済</span></Link>
									)}
									</p>
								)}
								{ option_id === '3' && (
									<p>
									{/* <Link to={`/${size_id}${_shop_id}4.html`}><span className="icon"><FaRegSquare style={{color: `#EB9DA8`}} /></span><span style={{fontSize:`0.7rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}>らくベビ使用済</span></Link><br /> */}
									{ (size_id === 'ss' || size_id === 's' || size_id === 'm') && (
										<Link to={`/${size_id}${_shop_id}.html`}><span className="icon"><FaCheckSquare style={{color: `#EB9DA8`}} /></span><span style={{fontSize:`0.7rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}><strong>クーポンコード使用済</strong></span></Link>
									)}
									</p>
								)}
								{ option_id === '4' && (
									<p>
									{/* <Link to={`/${size_id}${_shop_id}3.html`}><span className="icon"><FaCheckSquare style={{color: `#EB9DA8`}} /></span><span style={{fontSize:`0.7rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}><strong>らくベビ使用済</strong></span></Link><br /> */}
									{ (size_id === 'ss' || size_id === 's' || size_id === 'm') && (
										<Link to={`/${size_id}${_shop_id}2.html`}><span className="icon"><FaCheckSquare style={{color: `#EB9DA8`}} /></span><span style={{fontSize:`0.7rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}><strong>クーポンコード使用済</strong></span></Link>
									)}
									</p>
								)}
								</>
							)}
							</div>
							<div class="column is-4" style={{paddingLeft: `0.0rem`, paddingBottom: `0.9rem`}}>
								<div className="has-text-centered" style={{paddingTop: `0.3rem`, paddingBottom: `0.0rem`}}>
									<p style={{fontSize:`0.7rem`, marginTop: `0.0rem`}}><a href={`https://hula-la.com/campaign${_shop_id}.html`} target='_blank' style={{color: `#0783c2`}}>{ShopNameTag(shop_id, false)} キャンペーン情報</a></p>
								</div>              
							</div>
							<div class="column is-3" style={{paddingBottom: `0.9rem`}}>
								<div class="is-flex is-justify-content-flex-end"style={{marginBottom: `0rem`}}>
									<div className={`dropdown ${isActive ? 'is-active' : ''}`}>
										<div class="dropdown-trigger">
											<button onClick={toggleDropdown} className="button" aria-haspopup="true" aria-controls="dropdown-menu" style={{fontSize:`0.6rem`}}>
												<span>{ShopNameSelected(selected)}</span>
												<span class="icon is-small">
													<FaChevronDown color="#EB9DA8" size="1.0em" />
												</span>
											</button>
										</div>
										<div class="dropdown-menu" id="dropdown-menu" role="menu">
											<div class="dropdown-content">
												<a href="#" class={`dropdown-item ${selected === 'all' ? 'is-active' : ''}`} onClick={() => handleItemClick('', 'all', option_id)} style={{fontSize:`0.6rem`}}>
													{ShopName('all')}</a>
												<a href="#" class={`dropdown-item ${selected === 'amazon' ? 'is-active' : ''}`} onClick={() => handleItemClick('_amazon', 'amazon', option_id)} style={{fontSize:`0.6rem`}}>
													{ShopName('amazon')}</a>
												<a href="#" class={`dropdown-item ${selected === 'rakuten' ? 'is-active' : ''}`} onClick={() => handleItemClick('_rakuten', 'rakuten', option_id)} style={{fontSize:`0.6rem`}}>
													{ShopName('rakuten')}</a>
												<a href="#" class={`dropdown-item ${selected === 'yahoo' ? 'is-active' : ''}`} onClick={() => handleItemClick('_yahoo', 'yahoo', option_id)} style={{fontSize:`0.6rem`}}>
													{ShopName('yahoo')}</a>
												<a href="#" class={`dropdown-item ${selected === 'lohaco' ? 'is-active' : ''}`} onClick={() => handleItemClick('_lohaco', 'lohaco', option_id)} style={{fontSize:`0.6rem`}}>
													{ShopName('lohaco')}</a>
												<a href="#" class={`dropdown-item ${selected === 'aupay' ? 'is-active' : ''}`} onClick={() => handleItemClick('_aupay', 'aupay', option_id)} style={{fontSize:`0.6rem`}}>
													{ShopName('aupay')}</a>
												{/* <a href="#" class={`dropdown-item ${selected === 'other' ? 'is-active' : ''}`} onClick={() => handleItemClick('_other', 'other', option_id)} style={{fontSize:`0.6rem`}}>
													{ShopName('other')}</a> */}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="columns is-multiline is-gapless">
						<div className="column is-half is-vcentered">
							<div class="table-container">
								<table class="table is-bordered has-text-centered">
									<thead>
										<tr id="table1" style={{fontSize:`0.9rem`, backgroundColor: `#EB9DA8`}}>
											<th style={{color: `#ffffff`}}>サイズ</th>
											{ targets_1 === true && (
											<th style={{color: `#ffffff`}}>1位</th>
											)}
											{ targets_2 === true && (
											<th style={{color: `#ffffff`}}>2位</th>
											)}
											{ targets_3 === true && (
											<th style={{color: `#ffffff`}}>3位</th>
											)}
										</tr>
									</thead>
									<tbody>
									{targets.map(target => {
										const type = data.allTypesYaml.edges.find(edge => {	return ( edge.node.cid === target.type )})
										const brand = data.allBrandsYaml.edges.find(edge => {	return ( edge.node.cid === target.brand )})
										const sizeStr = ((type.node.cid !== 'wipe') && (type.node.cid !== 'handwipe'))? 'サイズ' : 'タイプ' 
										const itemStr = ((type.node.cid !== 'wipe') && (type.node.cid !== 'handwipe'))? 'オムツ' : type.node.description
										let rankItems = gerRankItem(filteredContentfulItems, data, type, brand, size_id)
										if(rankItems.lentgh >= 1){
											description += ` ${brand.node.description}${type.node.description}-${nameSubbrand(data, rankItems[0])}:${rankItems[0].node.itemList[0].per.toFixed(1)}円 `
										}
										if(!rankItems[0]){
											return
										}
										return(
											<tr>
												{ rankItems[0] && (
													<td id = {`${brand.node.cid}_${type.node.cid}`} style={{backgroundColor: `#EB9DA8`}}>
														<span style={{fontSize:`0.9rem`}}><strong style={{color: `#ffffff`}}>{nameBrand(data, rankItems[0])}</strong></span>
														<br />
														<span style={{fontSize:`0.6rem`}}><strong style={{color: `#ffffff`}}>{type.node.description}</strong></span>
													</td>
												)}
												{ !rankItems[0] && (
													<td>
													</td>
												)}
												{ rankItems[0] && (
													<td>
														<Link to={linkUrl(data, type, rankItems[0], shop_id, option_id)}>
															<span style={{fontSize:`0.7rem`, color: `#363636`}}><strong>{nameSubbrand(data, rankItems[0])}</strong></span>
															<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
																<Image filename={ImageFilename(gerBrand(data, rankItems[0]).node.cid, type.node.cid, gerSize(data, rankItems[0]).node.cid, gerSubbrand(data, rankItems[0]).node.subbrand_id, rankItems[0].node.itemList[0])} alt={`${gerBrand(data, rankItems[0]).node.description}${gerSubbrand(data, rankItems[0]).description}${type.node.description}タイプ${gerSize(data, rankItems[0]).node.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
															</div>
															<span style={{color: `#ff0000`}} className="is-size-6">&nbsp;<strong>{rankItems[0].node.itemList[0].per.toFixed(1)}</strong><span style={{fontSize:`0.7rem`}}> <strong>円</strong>/枚</span></span>
															<br />
															<span style={{fontSize:`0.7rem`, color: `#0783c2`}}>&nbsp;&nbsp;<strong>{nameShop(data, rankItems[0])}</strong></span>
														</Link>
													</td>
												)}
												{ targets_1 === true && !rankItems[0] && (
													<td>
													</td>
												)}
												{ rankItems[1] && (
													<td>
														<Link to={linkUrl(data, type, rankItems[1], shop_id, option_id)}>
															<span style={{fontSize:`0.7rem`, color: `#363636`}}><strong>{nameSubbrand(data, rankItems[1])}</strong></span>
															<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
																<Image filename={ImageFilename(gerBrand(data, rankItems[1]).node.cid, type.node.cid, gerSize(data, rankItems[0]).node.cid, gerSubbrand(data, rankItems[1]).node.subbrand_id, rankItems[1].node.itemList[0])} alt={`${gerBrand(data, rankItems[1]).node.description}${gerSubbrand(data, rankItems[1]).description}${type.node.description}タイプ${gerSize(data, rankItems[0]).node.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
															</div>
															<span style={{color: `#ff0000`}} className="is-size-6">&nbsp;<strong>{rankItems[1].node.itemList[0].per.toFixed(1)}</strong><span style={{fontSize:`0.7rem`}}> <strong>円</strong>/枚</span></span>
															<br />
															<span style={{fontSize:`0.7rem`, color: `#0783c2`}}>&nbsp;&nbsp;<strong>{nameShop(data, rankItems[1])}</strong></span>
														</Link>
													</td>
												)}
												{ targets_2 === true && !rankItems[1] && (
													<td>
													</td>
												)}
												{ rankItems[2] && (
													<td>
														<Link to={linkUrl(data, type, rankItems[2], shop_id, option_id)}>
															<span style={{fontSize:`0.7rem`, color: `#363636`}}><strong>{nameSubbrand(data, rankItems[2])}</strong></span>
															<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
																<Image filename={ImageFilename(gerBrand(data, rankItems[2]).node.cid, type.node.cid, gerSize(data, rankItems[0]).node.cid, gerSubbrand(data, rankItems[2]).node.subbrand_id, rankItems[2].node.itemList[0])} alt={`${gerBrand(data, rankItems[2]).node.description}${gerSubbrand(data, rankItems[2]).description}${type.node.description}タイプ${gerSize(data, rankItems[0]).node.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
															</div>
															<span style={{color: `#ff0000`}} className="is-size-6">&nbsp;<strong>{rankItems[2].node.itemList[0].per.toFixed(1)}</strong><span style={{fontSize:`0.7rem`}}> <strong>円</strong>/枚</span></span>
															<br />
															<span style={{fontSize:`0.7rem`, color: `#0783c2`}}>&nbsp;&nbsp;<strong>{nameShop(data, rankItems[2])}</strong></span>
														</Link>
													</td>
												)}
												{ targets_3 === true && !rankItems[2] && (
													<td>
													</td>
												)}
											</tr>
										)
									})}
									</tbody>
								</table>
							</div>
						</div>

						{ targets2.length > 0 && (
						<div className="column is-half is-vcentered">
							<div class="table-container">
								<table class="table is-bordered has-text-centered">
									<thead>
										<tr id="table2" style={{fontSize:`0.9rem`, backgroundColor: `#EB9DA8`}}>
											<th style={{color: `#ffffff`}}>サイズ</th>
											{ targets2_1 === true && (
											<th style={{color: `#ffffff`}}>1位</th>
											)}
											{ targets2_2 === true && (
											<th style={{color: `#ffffff`}}>2位</th>
											)}
											{ targets2_3 === true && (
											<th style={{color: `#ffffff`}}>3位</th>
											)}
										</tr>
									</thead>
									<tbody>
									{targets2.map(target => {
										const type = data.allTypesYaml.edges.find(edge => {	return ( edge.node.cid === target.type )})
										const brand = data.allBrandsYaml.edges.find(edge => {	return ( edge.node.cid === target.brand )})
										const sizeStr = ((type.node.cid !== 'wipe') && (type.node.cid !== 'handwipe'))? 'サイズ' : 'タイプ' 
										const itemStr = ((type.node.cid !== 'wipe') && (type.node.cid !== 'handwipe'))? 'オムツ' : type.node.description
										let rankItems = gerRankItem(filteredContentfulItems, data, type, brand, size_id)
										if(rankItems.lentgh >= 1){
											description += ` ${brand.node.description}${type.node.description}-${nameSubbrand(data, rankItems[0])}:${rankItems[0].node.itemList[0].per.toFixed(1)}円 `
										}
										if(!rankItems[0]){
											return
										}
										return(
											<tr>
												{ rankItems[0] && (
													<td id = {`${brand.node.cid}_${type.node.cid}`} style={{backgroundColor: `#EB9DA8`}}>
														<span style={{fontSize:`0.9rem`}}><strong style={{color: `#ffffff`}}>{nameBrand(data, rankItems[0])}</strong></span>
														<br />
														<span style={{fontSize:`0.6rem`}}><strong style={{color: `#ffffff`}}>{type.node.description}</strong></span>
													</td>
												)}
												{ !rankItems[0] && (
													<td>
													</td>
												)}
												{ rankItems[0] && (
													<td>
														<Link to={linkUrl(data, type, rankItems[0], shop_id, option_id)}>
															<span style={{fontSize:`0.7rem`, color: `#363636`}}><strong>{nameSubbrand(data, rankItems[0])}</strong></span>
															<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
																<Image filename={ImageFilename(gerBrand(data, rankItems[0]).node.cid, type.node.cid, gerSize(data, rankItems[0]).node.cid, gerSubbrand(data, rankItems[0]).node.subbrand_id, rankItems[0].node.itemList[0])} alt={`${gerBrand(data, rankItems[0]).node.description}${gerSubbrand(data, rankItems[0]).description}${type.node.description}タイプ${gerSize(data, rankItems[0]).node.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
															</div>
															<span style={{color: `#ff0000`}} className="is-size-6">&nbsp;<strong>{rankItems[0].node.itemList[0].per.toFixed(1)}</strong><span style={{fontSize:`0.7rem`}}> <strong>円</strong>/枚</span></span>
															<br />
															<span style={{fontSize:`0.7rem`, color: `#0783c2`}}>&nbsp;&nbsp;<strong>{nameShop(data, rankItems[0])}</strong></span>
														</Link>
													</td>
												)}
												{ targets2_1 === true && !rankItems[0] && (
													<td>
													</td>
												)}
												{ rankItems[1] && (
													<td>
														<Link to={linkUrl(data, type, rankItems[1], shop_id, option_id)}>
															<span style={{fontSize:`0.7rem`, color: `#363636`}}><strong>{nameSubbrand(data, rankItems[1])}</strong></span>
															<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
																<Image filename={ImageFilename(gerBrand(data, rankItems[1]).node.cid, type.node.cid, gerSize(data, rankItems[0]).node.cid, gerSubbrand(data, rankItems[1]).node.subbrand_id, rankItems[1].node.itemList[0])} alt={`${gerBrand(data, rankItems[1]).node.description}${gerSubbrand(data, rankItems[1]).description}${type.node.description}タイプ${gerSize(data, rankItems[0]).node.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
															</div>
															<span style={{color: `#ff0000`}} className="is-size-6">&nbsp;<strong>{rankItems[1].node.itemList[0].per.toFixed(1)}</strong><span style={{fontSize:`0.7rem`}}> <strong>円</strong>/枚</span></span>
															<br />
															<span style={{fontSize:`0.7rem`, color: `#0783c2`}}>&nbsp;&nbsp;<strong>{nameShop(data, rankItems[1])}</strong></span>
														</Link>
													</td>
												)}
												{ !rankItems[1] && (
													<td>
													</td>
												)}
												{ targets2_2 === true && rankItems[2] && (
													<td>
														<Link to={linkUrl(data, type, rankItems[2], shop_id, option_id)}>
															<span style={{fontSize:`0.7rem`, color: `#363636`}}><strong>{nameSubbrand(data, rankItems[2])}</strong></span>
															<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
																<Image filename={ImageFilename(gerBrand(data, rankItems[2]).node.cid, type.node.cid, gerSize(data, rankItems[0]).node.cid, gerSubbrand(data, rankItems[2]).node.subbrand_id, rankItems[2].node.itemList[0])} alt={`${gerBrand(data, rankItems[2]).node.description}${gerSubbrand(data, rankItems[2]).description}${type.node.description}タイプ${gerSize(data, rankItems[0]).node.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
															</div>
															<span style={{color: `#ff0000`}} className="is-size-6">&nbsp;<strong>{rankItems[2].node.itemList[0].per.toFixed(1)}</strong><span style={{fontSize:`0.7rem`}}> <strong>円</strong>/枚</span></span>
															<br />
															<span style={{fontSize:`0.7rem`, color: `#0783c2`}}>&nbsp;&nbsp;<strong>{nameShop(data, rankItems[2])}</strong></span>
														</Link>
													</td>
												)}
												{ targets2_3 === true && !rankItems[2] && (
													<td>
													</td>
												)}
											</tr>
										)
									})}
									</tbody>
								</table>
							</div>
						</div>
						)}

					</div>
				</div>
			</>
		<Seo title={title} description={description} canonical={location.pathname} />
  </Layout>
	)
}

function gerRankItem(filteredContentfulItems, data, type, _brand, size_id){
	let index = 0
	let rankItems = []
	filteredContentfulItems.map(edge => {
		if(index >= 3){
			return 
		}
		if(edge.node.typeId !== type.node.cid || edge.node.sizeId !== size_id) return
		const brand = data.allBrandsYaml.edges.find(n => {
			return n.node.cid === edge.node.brandId
		})
		const subbrand = data.allSubbrandsYaml.edges.find(n => {
			return (n.node.brand_id === edge.node.brandId) && (n.node.subbrand_id === edge.node.subbrandId)
		})
		if(!brand) return
		if(!edge.node.itemList.length) return
		if(brand.node.cid !== _brand.node.cid) return
		rankItems[index] = edge
		index = index + 1
	})
	return rankItems
}

function gerSize(data, edge){
	const size = data.allSizesYaml.edges.find(n => {
		return n.node.cid === edge.node.sizeId
	})
	return size
}

function gerBrand(data, edge){
	const brand = data.allBrandsYaml.edges.find(n => {
		return n.node.cid === edge.node.brandId
	})
	return brand
}

function gerSubbrand(data, edge){
	const subbrand = data.allSubbrandsYaml.edges.find(n => {
		return (n.node.brand_id === edge.node.brandId) && (n.node.subbrand_id === edge.node.subbrandId)
	})
	return subbrand
}

function nameBrand(data, edge){
	const brand = gerBrand(data, edge)
	let ret = `${brand.node.description}`
	if(brand.node.cid === 'tore'){
		ret = 'トレパンマン'
	}
	else if(brand.node.cid === 'oya'){
		ret = 'オヤスミマン'
	}
	if(ret === '恵生堂'){
		ret = 'メグミ'
	}
	return ret;
}

function nameMainSize(data, size_id){
	const brand = data.allSizesYaml.edges.find(n => {
		return n.node.cid === size_id
	})
	let ret = `${brand.node.description}`
	return ret;
}

function nameSubbrand(data, edge){
	const renames = [
		{brand:'goon', 		subbrand:'sara',	name:'まっさらさら'},
		{brand:'goon', 		subbrand:'mash',	name:'はじめての肌着'},
		{brand:'goon', 		subbrand:'yawa',	name:'やわらか'},
		{brand:'goon', 		subbrand:'gungun',name:'12時間'},
		{brand:'goon', 		subbrand:'gunkyuu_p',name:'プリンセス'},
		{brand:'pampers', subbrand:'sara',	name:'さらケア'},
		{brand:'pampers', subbrand:'haji',	name:'肌いち'},
		{brand:'pampers', subbrand:'sotu',	name:'卒業パンツ'},
		{brand:'pampers', subbrand:'onii',	name:'おにいさん'},
		{brand:'pampers', subbrand:'onee',	name:'おねえさん'},
		{brand:'merries', subbrand:'sara',	name:'エアスルー'},
		{brand:'merries', subbrand:'firs',	name:'メリファ'},
		{brand:'moony', 	subbrand:'more',	name:'モレ0'},
		{brand:'moony', 	subbrand:'moreb',	name:'モレ0(男)'},
		{brand:'moony', 	subbrand:'moreg',	name:'モレ0(女)'},
		{brand:'moony', 	subbrand:'mash',	name:'マシュマロ'},
		{brand:'moony', 	subbrand:'mashb',	name:'マシュマロ(男)'},
		{brand:'moony', 	subbrand:'mashg',	name:'マシュマロ(女)'},
		{brand:'moony', 	subbrand:'airb',	name:'ｽｰﾊﾟｰBIG(男)'},
		{brand:'moony', 	subbrand:'airg',	name:'ｽｰﾊﾟｰBIG(女)'},
		{brand:'moony', 	subbrand:'tei',		name:'低刺激'},
		{brand:'mamypoko', 	subbrand:'plarail',	name:'トミカ'},
		{brand:'mirafeel', 	subbrand:'adjust',	name:'ミラフィール'},

		{brand:'goon', 		subbrand:'swim',	name:'スイミング'},
		{brand:'goon', 		subbrand:'swimb',	name:'スイミング(男)'},
		{brand:'goon', 		subbrand:'swimg',	name:'スイミング(女)'},
		{brand:'moony', 	subbrand:'swimb',	name:'水あそび(男)'},
		{brand:'moony', 	subbrand:'swimg',	name:'水あそび(女)'},

		{brand:'pigeon', 	subbrand:'yawa',	name:'やわらか厚手'},
		{brand:'pigeon', 	subbrand:'name',	name:'なめらか厚手'},
		{brand:'pigeon', 	subbrand:'prem',	name:'極上厚手'},
		{brand:'pigeon', 	subbrand:'fuwa',	name:'ふわふわ厚手'},
		{brand:'pigeon', 	subbrand:'naga',	name:'おしりナップ'},
		{brand:'showa', 	subbrand:'akapink',	name:'赤ちゃん(ピンク)'},
		{brand:'showa', 	subbrand:'akamin',	name:'赤ちゃん(小さめ)'},
		{brand:'showa', 	subbrand:'inai',	name:'いないいないばぁ'},
		{brand:'lecbe', 	subbrand:'toire',	name:'トイレに流せる'},
		{brand:'lecbe', 	subbrand:'atude',	name:'99.9水分たっぷり'},

	]
	const brand = gerBrand(data, edge)
	const subbrand = gerSubbrand(data, edge)
	let ret = `${subbrand.node.description}`
	for(let rename of renames){
		if((brand.node.cid === rename.brand) && (subbrand.node.subbrand_id === rename.subbrand)){
			ret = rename.name
			break
		}
	}
	return ret;
}

function nameShop(data, edge){
	const shopId = edge.node.itemList[0].shopId
	const shop = edge.node.itemList[0].shop
	let ret = ''
	if(shopId === 'aeon'){
		ret = 'イオン'
	}
	else if(shopId === 'akachan'){
		ret = 'アカホン'
	}
	else if(shopId === 'amazon'){
		if(shop.includes('定期')){
			ret = 'Amazon定期便'
		}else{
			ret = 'Amazon'
		}
	}
	else if(shopId === 'babiesrus'){
		ret = 'ベビーザらス'
	}
	else if(shopId === 'lohaco'){
		ret = 'LOHACO'
	}
	else if(shopId === 'matsukiyo'){
		ret = 'マツキヨ'
	}
	else if(shopId === 'rakuten'){
		if(shop.includes('ベビー館')){
			ret = '楽天24ベビー館'
		}else{
			ret = '楽天'
		}
	}
	else if(shopId === 'yahoo'){
		ret = 'ヤフショ'
	}
	else if(shopId === 'aupay'){
		ret = 'au PAY'
	}
	else if(shopId === 'nishimatsuya'){
		ret = '西松屋'
	}
	else if(shopId === 'cainz'){
		ret = 'カインズ'
	}
	else if(shopId === 'costco'){
		ret = 'コストコ'
	}
	else if(shopId === 'tsuruha'){
		ret = 'ツルハ'
	}
	else if(shopId === 'kohnan'){
		ret = 'コーナン'
	}
	else if(shopId === 'shimamura'){
		ret = 'しまむら'
	}
	return ret;
}

function linkUrl(data, type, edge, shop_id, option_id){
	const size = gerSize(data, edge)
	const brand = gerBrand(data, edge)
	const subbrand = gerSubbrand(data, edge)

	const premium_subbrands = ['pampers_haji','pampers_hada','merries_firs','moony_nat','moony_tei','pampers_pure','pampers_chyou','pampers_chyou_familiar','whito_3h','whito_12h']
	const long_subbrands = ['oyas','yoru','gungun','oyab','oyag','max','nikids','nijuni','asa','anshin']
	const toitore_subbrands = ['toreb','toreg','sotu','byebye','toire','onii','onee']

	let category =  ((type.node.cid !== 'milk_powder') && (type.node.cid !== 'milk_liquid'))? 'index' : 'milk'

	if(premium_subbrands.includes(brand.node.cid + '_' + subbrand.node.subbrand_id)){ category = 'premium' }
	else if(long_subbrands.includes(subbrand.node.subbrand_id)){	category = 'longtime'	}
	else if(toitore_subbrands.includes(subbrand.node.subbrand_id) && (type.node.cid !== 'wipe')){	category = 'toitore' } // トイレに流せるおしりふきがtoioreにならないようにwipe以外を条件に 

	let ret = `/${category}/${type.node.cid}_${size.node.cid}_${brand.node.cid}_${shop_id}${option_id}`
	if((category === 'index') && ((brand.node.cid === 'mamabear') || (brand.node.cid === 'whito') || (brand.node.cid === 'mirafeel') || (brand.node.cid === 'pigeon') || (brand.node.cid === 'keiseidou') || (brand.node.cid === 'babiesrus'))){
		ret = `/${type.node.cid}_${size.node.cid}_${brand.node.cid}_${subbrand.node.subbrand_id}.html`
	}
	return ret;
}

function ShopLink(size_id, _shop_id, option_id){
  let href = `/${size_id}${_shop_id}${option_id}.html`
  return href
}

export const query = graphql`
	query {
		allTypesYaml {
			edges {
				node {
					cid
					description
				}
			}
		}
		allSizesYaml {
			edges {
				node {
					cid
					description
				}
			}
		}
		allBrandsYaml {
			edges {
				node {
					cid
					description
				}
			}
		}
		allSubbrandsYaml {
			edges {
				node {
					brand_id
					subbrand_id
					description
					types {
						type_id 
						sizes {
							size_id
							min
							max
							unit
							num	
						}
					}
				}
			}
		}
		allContentfulItems {
			edges {
				node {
					brandId
					subbrandId
					typeId
					sizeId
					itemList {
						num
						shopId
						shop
						href
						per
						per_org
						price
						price_org
						sendPrice
						souryou
						txt
						point
						star
						review
						reviewUrl
					}
					updatedAt
				}
			}
		}
	}
`
export default TableDiaperSize
